<script>
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { onMounted, ref, watch, computed } from 'vue'
import { useMeta } from 'vue-meta'

import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import formModalClient from '@/components/modalClient/formModalClient.vue'
import formModalOnboarding from '@/components/modalClient/formModalOnboarding.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTable from '@/components/shared/table.vue'
import { useInfiniteScroll } from '@/composables/utils/useInfiniteScroll'
import { useInstitution } from '@/composables/utils/useInstitution'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { useOnboardingStatus } from '@/composables/utils/useOnboardingStatus'
import { usePagination } from '@/composables/utils/usePagination'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import {
  fetchClients,
  fetchClient,
  updateClient,
  deleteClient,
  fetchClientOnboarding,
  updateClientOnboarding,
  updateDocsOnboarding,
  updateRegistrationForm
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useWss } from '@/stores/wss'

export default {
  name: 'appClients',

  components: {
    formDate,
    formInput,
    formSelect,
    appLoading,
    appTable,
    formModalClient,
    formModalOnboarding,
    appModal
  },

  props: {
    search: String,
    clientId: String,
    onboardingStatus: String,
    onboardedAt: String,
    orderBy: String,
    id: String,
    idOnboarding: String
  },

  setup(props) {
    useMeta({ title: 'Clients' })
    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const { totalPages, currentPage, isLastPage, setPagination, nextPage } = usePagination()

    const searchValue = ref(props.search)
    const clientIdValue = ref(props.clientId)
    const onboardingStatusValue = ref(props.onboardingStatus)
    const openingValue = ref(props.onboardedAt)

    const idEditClient = ref(props.id)
    const idEditOnboarding = ref(props.idOnboarding)

    const permissions = usePermissions()

    const operations = ref('')
    const result = ref('')
    const loading = ref(false)

    const orderOptions = ref([
      { key: 'NAME', name: 'Nome' },
      { key: 'LAST_UPDATE', name: 'Edição' },
      { key: 'LAST_CLOSED_OPERATION', name: 'Último fechamento' }
    ])

    const clientTypesOptions = ref([
      { key: '', name: 'Todos' },
      { key: 'PF', name: 'PF' },
      { key: 'PJ', name: 'PJ' }
    ])

    const orderValue = ref(props.orderBy ? props.orderBy : 'NAME')
    const lastClosedOperationNewerThanValue = ref(props.lastClosedOperationNewerThan)
    const institutionValue = ref(props.institution)
    const clientTypeValue = ref(props.clientType)

    const { fetchInstitutionOptions } = useInstitution()
    const institutionOptions = fetchInstitutionOptions({ includeAll: true })

    const valuesToWatch = [
      onboardingStatusValue,
      openingValue,
      orderValue,
      lastClosedOperationNewerThanValue,
      institutionValue,
      clientTypeValue
    ]

    const { fetchOnboardingStatusOptions } = useOnboardingStatus()

    const onboardingStatusOptions = fetchOnboardingStatusOptions()

    useSearchThrottle(searchValue, () => {
      resetAndUpdateClients()
    })

    useSearchThrottle(clientIdValue, () => {
      resetAndUpdateClients()
    })

    const { setNoScroll } = useNoScroll()

    const { institutionType } = useInstitution()

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      { key: 'clientType', label: 'Tipo do cliente', type: 'text' },
      { key: 'onboardedAt', label: 'Data de abertura', type: 'date', format: 'dd/MM/yyyy HH:mm' },
      { key: 'onboardingStatus', label: 'Status', type: 'onboardingStatusType' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          {
            action: 'editOnboarding',
            label: 'Editar Onboarding',
            color: '#d0af00',
            emitEvent: 'emitEditOnboarding'
          },
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchOperations = async (config) => {
      loading.value = true
      try {
        const response = await fetchClients(config)
        operations.value =
          currentPage.value === 0
            ? response.data.payload
            : [...operations.value, ...response.data.payload]

        result.value = response.data?.page?.totalCount

        setPagination(response.data.page.pageCount, currentPage.value)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    useInfiniteScroll(() => {
      if (
        !loading.value &&
        operations.value.length !== 0 &&
        currentPage.value < totalPages.value - 1
      ) {
        nextPage()
        updateOperationsWithFilters()
      }
    })

    const resetOperations = () => {
      operations.value = ''
      currentPage.value = 0
    }

    const updateOperationsWithFilters = () => {
      const config = {
        page: currentPage.value,
        search: searchValue.value,
        clientId: clientIdValue.value ? clientIdValue.value.replace(/[.\s/-]/g, '') : undefined,
        onboardingStatus: onboardingStatusValue.value,
        onboardedAt: openingValue.value,
        orderBy: orderValue.value,
        lastClosedOperationNewerThan: lastClosedOperationNewerThanValue.value,
        institution: institutionValue.value,
        clientType: clientTypeValue.value
      }

      const queryParams = { ...config }
      delete queryParams.page
      router.push({ name: 'Clients', query: queryParams })

      fetchOperations(config)
    }

    const resetAndUpdateClients = () => {
      resetOperations()
      updateOperationsWithFilters()
    }

    const wssStore = useWss()

    watch(
      () => wssStore.getNewClient.id,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          resetAndUpdateClients()
        }
      }
    )

    valuesToWatch.forEach((refValue) => {
      watch(refValue, resetAndUpdateClients)
    })

    onMounted(() => {
      if (idEditClient.value) {
        let item = {
          clientId: idEditClient.value
        }
        handleEdit(item)
      } else if (idEditOnboarding.value) {
        let item = {
          clientId: idEditOnboarding.value
        }
        handleEditOnboarding(item)
      }

      const config = {
        page: 0,
        search: searchValue.value,
        clientId: clientIdValue.value ? clientIdValue.value.replace(/[.\s/-]/g, '') : undefined,
        onboardingStatus: onboardingStatusValue.value,
        onboardedAt: openingValue.value,
        orderBy: orderValue.value,
        lastClosedOperationNewerThan: lastClosedOperationNewerThanValue.value,
        institution: institutionValue.value,
        clientType: clientTypeValue.value
      }
      fetchOperations(config)
    })

    const title = ref('')
    const modalEnabled = ref(false)
    const modalEnabledOnboarding = ref(false)

    const editModeEnabled = ref(false)
    const loadingButton = ref(false)
    const initialFormData = ref({})

    const newClient = () => {
      title.value = 'Novo Cliente'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        if (modalEnabled.value) handleSubmitClient(formData)
      } else {
        if (modalEnabled.value) handleUpdateClient(formData)
        if (modalEnabledOnboarding.value) handleUpdateClientOnboarding(formData)
      }
    }

    const handleSubmitClient = async (formData) => {
      try {
        loadingButton.value = true
        await updateClient(formData)
        handleFormClose()
        resetAndUpdateClients()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateClient = async (updatedForm) => {
      try {
        loadingButton.value = true
        await updateClient(updatedForm)
        handleFormClose()
        resetAndUpdateClients()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateClientOnboarding = async (updatedForm) => {
      try {
        loadingButton.value = true
        const id = updatedForm.clientId
        await updateClientOnboarding(id, updatedForm)

        if (updatedForm?.docs?.length > 0) {
          await handleUpdateDocs(id, updatedForm.docs)
        }

        if (updatedForm?.registrationForm != null) {
          await handleUpdateRegistrationForm(id, updatedForm.registrationForm)
        }

        handleFormClose()
        resetAndUpdateClients()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateDocs = async (id, docs) => {
      try {
        await updateDocsOnboarding(id, docs)
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleUpdateRegistrationForm = async (id, registrationForm) => {
      try {
        await updateRegistrationForm(id, registrationForm)
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
      modalEnabledOnboarding.value = false

      const queryParams = {}
      router.push({ name: 'Clients', query: queryParams })
    }

    const handleEdit = async (item) => {
      try {
        let response = await fetchClient(item.clientId)
        title.value = 'Editar Cliente'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabled.value = true

        const config = {
          id: item.clientId
        }

        const queryParams = { ...config }
        router.push({ name: 'Clients', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleEditOnboarding = async (item) => {
      try {
        let response = await fetchClientOnboarding(item.clientId)
        title.value = 'Editar Onboarding'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabledOnboarding.value = true

        const config = {
          idOnboarding: item.clientId
        }

        const queryParams = { ...config }
        router.push({ name: 'Clients', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-client',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Após essa ação, você ira remover todos os dados relacionados ao cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.clientId
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteClient = async (item) => {
      try {
        loadingModal.value = true
        await deleteClient(item.id)
        operations.value = operations.value.filter((op) => op.clientId !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-client') {
        handleDeleteClient(item)
      }
    }

    return {
      permissions,
      searchValue,
      clientIdValue,
      onboardingStatusValue,
      onboardingStatusOptions,
      openingValue,
      orderValue,
      orderOptions,
      lastClosedOperationNewerThanValue,
      institutionValue,
      institutionOptions,
      clientTypeValue,
      clientTypesOptions,

      tableHeaders,
      operations,
      loading,

      institutionType,
      format,
      ptBR,

      isLastPage,

      newClient,
      modalEnabled,
      modalEnabledOnboarding,
      title,
      initialFormData,
      result,
      loadingButton,

      handleEdit,
      handleEditOnboarding,

      modalFormIsShown,
      loadingModal,
      modalConfig,
      handleDelete,
      handleConfirm,

      handleFormClose,
      handleFormCreation
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <form-modal-client
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <form-modal-onboarding
      v-if="modalEnabledOnboarding"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <div class="row title">
      <h2 class="col">Clientes</h2>
      <div class="col-12 col-md-3">
        <p class="result"><span>Resultado:</span> {{ result }} <span>clientes</span></p>
      </div>
    </div>

    <div class="row g-2 mt-3">
      <form-input
        class="col form-input"
        label="Cliente"
        v-model="searchValue"
        id="search"
        name="search"
        type="search"
        icon="bi bi-search"
        placeholder="Nome do cliente"
        autocomplete="off"
      />
      <form-input
        class="col form-input"
        label="CNPJ ou CPF"
        v-model="clientIdValue"
        id="clientId"
        name="clientId"
        type="search"
        icon="bi bi-search"
        autocomplete="off"
      />
      <form-select
        class="col form-input"
        label="Tipo"
        v-model="clientTypeValue"
        :items="clientTypesOptions"
        id="clientType"
        name="clientType"
      />
      <form-date
        class="col form-input"
        label="Data de abertura"
        v-model="openingValue"
        id="opening"
        name="opening"
        icon="bi bi-calendar-week"
      />
      <form-date
        class="col form-input"
        label="Último fechamento"
        v-model="lastClosedOperationNewerThanValue"
        id="opening"
        name="opening"
        icon="bi bi-calendar-week"
      />
      <form-select
        class="col form-input"
        label="Instituição"
        v-model="institutionValue"
        :items="institutionOptions"
        id="status"
        name="status"
      />
      <form-select
        class="col form-input"
        label="Status"
        v-model="onboardingStatusValue"
        :items="onboardingStatusOptions"
        id="status"
        name="status"
      />
      <form-select
        class="col form-input"
        label="Ordernar"
        v-model="orderValue"
        :items="orderOptions"
        id="status"
        name="status"
      />
    </div>

    <div class="row pb-5">
      <div class="col-12 form-table">
        <app-table
          v-if="operations.length > 0"
          :headers="tableHeaders"
          :items="operations"
          @edit="handleEdit"
          @emitEditOnboarding="handleEditOnboarding"
          @delete="handleDelete"
        />

        <div v-if="isLastPage && !loading" class="d-flex align-items-center mt-5">
          <h6 class="col mb-0 text-center">Isso é tudo!</h6>
        </div>

        <div
          v-if="operations.length === 0 && !loading"
          class="d-flex align-items-center placeholder"
        >
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
