import { usePermissions } from './usePermissions'

import { useAuthToken } from '@/stores/authToken'

const isProduction = process.env.VITE_APP_URL.includes('prod')

export const configs = {
  TRANSFERBANK: {
    platformUrlRedirection: isProduction
      ? 'https://admin.transferbank.com.br'
      : 'https://admin-stag.transferbank.com.br',
    acceptedUrls: ['https://admin.transferbank.com.br', 'https://admin-stag.transferbank.com.br'],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding.transferbank.com.br/'
      : 'https://onboarding-stag.transferbank.com.br/',
    key: 'TRANSFERBANK',
    name: 'transferbank',
    short_name: 'TRANSFER',
    tag: 'TFB',
    color: '#0AA092',
    isApp: true
  },
  DOURADA: {
    platformUrlRedirection: isProduction
      ? 'https://admin.dourada.com.br'
      : 'https://admin-dourada-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin.dourada.com.br',
      'https://admin-dourada-stag.transferbankfx.com.br',
      'https://admin-dourada.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding.dourada.com.br/'
      : 'https://onboarding-stag.dourada.com.br/',
    key: 'DOURADA',
    name: 'Dourada',
    short_name: 'DOURADA',
    tag: 'DOU',
    color: '#EFC132'
  },
  SVN: {
    platformUrlRedirection: isProduction
      ? 'https://admin-svn.transferbankfx.com.br'
      : 'https://admin-svn-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin-svn.transferbankfx.com.br',
      'https://admin-svn-stag.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding-svn.transferbankfx.com.br'
      : 'https://onboarding-svn-stag.transferbankfx.com.br',
    key: 'SVN',
    name: 'SVN',
    short_name: 'SVN',
    tag: 'SVN',
    color: '#BB9F8B'
  },
  MIRAE: {
    platformUrlRedirection: isProduction
      ? 'https://admin-transferbank.miraeinvest.com.br'
      : 'https://admin-mirae-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin-transferbank.miraeinvest.com.br',
      'https://admin-mirae-stag.transferbankfx.com.br',
      'https://admin-mirae.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding-mirae.transferbankfx.com.br/'
      : 'https://onboarding-mirae-stag.transferbankfx.com.br/',
    key: 'MIRAE',
    name: 'Mirae',
    short_name: 'MIR',
    tag: 'MIR',
    color: '#F58220',
    isApp: true
  },
  NORD: {
    platformUrlRedirection: isProduction
      ? 'https://admin-cambio.nordinvestimentos.com.br'
      : 'https://admin-nord-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin-cambio.nordinvestimentos.com.br',
      'https://admin-nord-stag.transferbankfx.com.br',
      'https://admin-nord.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding-cambio.nordinvestimentos.com.br'
      : 'https://onboarding-nord-stag.transferbankfx.com.br',
    key: 'NORD',
    name: 'Nord',
    short_name: 'NORD',
    tag: 'NOR',
    color: '#71c8d1',
    isApp: true
  },
  GNOSE: {
    platformUrlRedirection: isProduction
      ? 'https://admin.denicambio.com'
      : 'https://admin-gnose-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin.denicambio.com',
      'https://admin-gnose-stag.transferbankfx.com.br',
      'https://admin-gnose.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://cadastro.denicambio.com'
      : 'https://onboarding-gnose-stag.transferbankfx.com.br',
    key: 'GNOSE',
    name: 'Gnose',
    short_name: 'GNOSE',
    tag: 'GNO',
    color: '#D3A14E',
    isApp: true
  },
  MPDI: {
    platformUrlRedirection: isProduction
      ? 'https://admin.mapacambio.com.br'
      : 'https://admin-mpdi-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin.mapacambio.com.br',
      'https://admin-mpdi-stag.transferbankfx.com.br',
      'https://admin-mpdi.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding.mapacambio.com.br'
      : 'https://onboarding-mpdi-stag.transferbankfx.com.br',
    key: 'MPDI',
    name: 'MPDI',
    short_name: 'MPDI',
    tag: 'MPDI',
    color: '#001231',
    isApp: true
  },
  OKEGEN: {
    platformUrlRedirection: isProduction
      ? 'https://admin.okegen.com.br'
      : 'https://admin-okegen-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin.okegen.com.br',
      'https://admin-okegen-stag.transferbankfx.com.br',
      'https://admin-okegen.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding.okegen.com.br'
      : 'https://onboarding-okegen-stag.transferbankfx.com.br',
    key: 'OKEGEN',
    name: 'OKEGEN',
    short_name: 'OKEGEN',
    tag: 'OKEGEN',
    color: '#1C4C80',
    isApp: true
  },
  MASFRA: {
    platformUrlRedirection: isProduction
      ? 'https://admin.masfra.com.br'
      : 'https://admin-masfra-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin.masfra.com.br',
      'https://admin-masfra-stag.transferbankfx.com.br',
      'https://admin-masfra.transferbankfx.com.br'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding.masfra.com.br'
      : 'https://onboarding-masfra-stag.transferbankfx.com.br',
    key: 'MASFRA',
    name: 'MASFRA',
    short_name: 'MASFRA',
    tag: 'MASFRA',
    color: '#090E5E',
    isApp: true
  },
  TPAY: {
    platformUrlRedirection: isProduction
      ? 'https://admin.transferbankpay.com.br/'
      : 'https://admin-tpay-stag.transferbankfx.com.br',
    acceptedUrls: [
      'https://admin.transferbankpay.com.br/',
      'https://admin-tpay-stag.transferbankfx.com.br',
      'http://localhost:8081'
    ],
    platformOnboardingUrl: isProduction
      ? 'https://onboarding.transferbankpay.com.br'
      : 'https://onboarding-tpay-stag.transferbankfx.com.br',
    key: 'TPAY',
    name: 'TPAY',
    short_name: 'TPAY',
    tag: 'TPAY',
    color: '#0AA092'
  }
}

export const getPlatformFromUrl = () => {
  const currentUrl = window.location.href
  for (const key in configs) {
    if (configs[key].acceptedUrls.some((url) => currentUrl.includes(url))) {
      document.documentElement.style.setProperty('--button-background', configs[key].color)
      document.documentElement.style.setProperty('--secondary-color', configs[key].color)
      document.documentElement.style.setProperty('--button-color', setTextColor(configs[key].color))
      return configs[key].key
    }
  }
  return configs['TRANSFERBANK'].key
}

const hexToRgb = (hex) => {
  let r = 0,
    g = 0,
    b = 0
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }
  return [r, g, b]
}

const calculateLuminance = (r, g, b) => {
  const a = [r, g, b].map(function (v) {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

const setTextColor = (hexColor) => {
  const [r, g, b] = hexToRgb(hexColor)
  const luminance = calculateLuminance(r, g, b)
  return luminance > 0.363 ? '#000000' : '#ffffff'
}

export const platformNameForRole = (role) => {
  return configs[role]?.name
}

export const platformUrlForRole = (role) => {
  return configs[role]?.platformUrlRedirection
}

export const platformOnboardingUrlForRole = (role) => {
  return configs[role]?.platformOnboardingUrl
}

export const isIncorrectUrlForRole = (role) => {
  const currentUrl = window.location.href
  const acceptedUrls = configs[role]?.acceptedUrls || []
  return !acceptedUrls.some((url) => currentUrl.includes(url))
}

export const isTransferbankAdminUser = () => {
  return useAuthToken().getInstitution === 'TRANSFERBANK'
}

export const isDouradaAdminUser = () => {
  return useAuthToken().getInstitution === 'DOURADA'
}

export const hasInputAllowed = () => {
  const { hasInputAllowed } = usePermissions()
  return hasInputAllowed() || isDouradaAdminUser()
}

export const hasSensitiveInputAllowed = () => {
  const { hasSensitiveInputAllowed } = usePermissions()
  return hasSensitiveInputAllowed()
}
