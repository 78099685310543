import { onMounted, onUnmounted } from 'vue'

export const useInfiniteScroll = (callback) => {
  let isEventRegistered = false

  const isScrolledToBottom = () => {
    const scrollY = window.scrollY
    const viewportHeight = window.innerHeight
    const scrollHeight = document.documentElement.scrollHeight

    return scrollY + viewportHeight >= scrollHeight - 100
  }

  const handleScroll = () => {
    if (isScrolledToBottom()) {
      callback()
    }
  }

  onMounted(() => {
    if (typeof window !== 'undefined' && !isEventRegistered) {
      window.addEventListener('scroll', handleScroll)
      isEventRegistered = true
    }
  })

  onUnmounted(() => {
    if (typeof window !== 'undefined' && isEventRegistered) {
      window.removeEventListener('scroll', handleScroll)
      isEventRegistered = false
    }
  })
}
